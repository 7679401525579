import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { title: "fragnach" },
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Register.vue"),
        meta: { title: "fragnach", requiresAuth: false },
    },
    {
        path: "/registration_complete",
        name: "RegistrationComplete",
        component: () =>
            import(
                /* webpackChunkName: "registrationcomplete" */ "../views/RegistrationComplete.vue"
            ),
        meta: { title: "fragnach", requiresAuth: false },
    },
    // {
    //     path: "/about",
    //     name: "About",
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "../views/About.vue"),
    //     meta: { title: "fragnach", requiresAuth: true },
    // },
    {
        path: "/templates",
        name: "Templates",
        component: () =>
            import(
                /* webpackChunkName: "templates" */ "../views/Templates.vue"
            ),
        meta: { title: "fragnach", requiresAuth: true },
    },
    {
        path: "/survey",
        props: route => ({
            token: route.query.token,
        }),
        name: "Surveys",
        component: () =>
            import(/* webpackChunkName: "surveys" */ "../views/Survey.vue"),
        meta: { title: "fragnach", requiresAuth: false },
    },
    {
        path: "/survey/:surveyId",
        props: true,
        name: "Micro-Surveys",
        component: () =>
            import(/* webpackChunkName: "surveys" */ "../views/Survey.vue"),
        meta: { title: "fragnach", requiresAuth: false },
    },
    {
        path: "/verify",
        props: route => ({
            token: route.query.token,
        }),
        name: "VerificationComplete",
        component: () =>
            import(
                /* webpackChunkName: "verificationcomplete" */ "../views/VerificationComplete.vue"
            ),
        meta: { title: "fragnach", requiresAuth: false },
    },
    {
        path: "/analysis/:surveyId",
        props: true,
        name: "Analysis",
        component: () =>
            import(/* webpackChunkName: "analysis" */ "../views/Analysis.vue"),
        meta: { title: "fragnach", requiresAuth: true },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});
const DEFAULT_TITLE = "fragnach";
router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta?.title || DEFAULT_TITLE;
    });
});

router.beforeEach(async (to, from, next) => {
    // routeguard
    const isUserLoggedIn = store.getters.isAuthenticated;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // if token is expired and route protected, push back to login

        if (!isUserLoggedIn) {
            // User is not logged in but trying to open page which requires
            next({
                path: "/",
                query: { redirect: to.fullPath },
            });
            store.commit("showSnackbar", {
                text: "Session abgelaufen",
                color: "error",
            });
        } else {
            // User is logged in and trying to open page which requires auth
            next();
        }
    } else if (to.name === "Login" && isUserLoggedIn) {
        // User logged in but still trying to open login page
        next({ name: "Home" });
    } else {
        // User is trying to open page which doesn't require auth
        next();
    }
});
export default router;
