
import { Component, Vue } from "vue-property-decorator";
import { AuthService } from "@/services/authService";
import { UserService } from "@/services/userService";
import FragnachButton from "@/components/customComponents/fragnachBtn.vue";

@Component({ components: { FragnachButton } })
export default class Login extends Vue {
    loading: boolean = false;
    email: string = "";
    password: string = "";

    async login(): Promise<void> {
        this.loading = true;
        const response = await AuthService.login(this.email, this.password);
        this.loading = false;
        if (response.access_token) {
            this.$store.commit("setToken", response.access_token);
            const user = await UserService.getMe(response.access_token);
            this.$store.commit("setUser", { user: user });
            let target: string = "/templates";
            if (this.$route.query.redirect) {
                target = this.$route.query.redirect.toString();
            }
            this.$router.push(target);
        } else {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: response,
            });
        }
    }
}
