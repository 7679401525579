import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: "#5f73e2",
                background: "#2b2d34",
                fragnachgreen: "#a7f3da",
                fragnachpink: "#F14B8E",
                fragnachyellow: "#ffcf52",
                fragnachpurple: "#DAC0ED",
                fragnachgrey: "#9A9FB0",
                fragnachSentimentGreen: "#D5EBB8",
                fragnachSentimentYellow: "#FFF5B8",
                fragnachSentimentOrange: "#FFCF52",
                fragnachSentimentRed: "#FFB252",
            },
        },
    },
    lang: {
        locales: {
            de,
            en,
        },
    },
});
