import { User } from "@/interfaces/user";
import axios from "axios";
import request from "axios";

export class UserService {
    public static async getMe(token: string): Promise<User> {
        const uri = `/api/users/me`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.get(uri, config);
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async updateMe(
        attributes: Partial<User>,
        token: string
    ): Promise<User> {
        const uri = `/api/users/me`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.patch(uri, attributes, config);
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }
}
