
import { User } from "@/interfaces/user";
import { UserService } from "@/services/userService";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class Profile extends Vue {
    public get user(): User | null {
        return this.$store.state?.user;
    }

    public logout(): void {
        this.$store.commit("logout");
        this.$router.push("/");
        this.close();
    }

    public async updateUser(): Promise<User | undefined> {
        if (!this.user) return;

        const updated = await UserService.updateMe(
            this.user,
            this.$store.state.token
        );
        if (!updated.id) {
            this.$store.commit("showSnackbar", {
                color: "error",
                text: updated,
            });
            return;
        }
        this.$store.commit("showSnackbar", {
            color: "fragnachgreen",
            text: "User Profil aktualisiert",
        });
        return updated;
    }

    @Emit()
    public close(): boolean {
        return true;
    }
}
